/* Fix user-agent */

html * {
  max-height: 1000000px;
}

* {
  box-sizing: border-box;
}

body {
  background: black;
  color: #cccccc;
  font-smoothing: antialiased;
  font-family: @display-family;
  line-height: 1.3;
  font-weight: 500;
  font-size: 0.95em;
}

ul {
  list-style: none;
  word-wrap: break-word;
}

/* Pages */

#login-form-outer {
  clear: both;
  visibility: hidden;
}

#form-submit-button {
  @media @phone {
    width: 100%;
  }
}

#infobar {
  width: 100%;
  height: 25px;
  line-height: 25px;
  border: 0;
  margin: 0;
}

#extrasbar {
  width: 100%;
  height: 25px;
  line-height: 1em;
  border: 0;
  margin: 0 0 0.2em;
  display: flex;
  flex-direction: column;
  flex: auto;
}

#prompt-inclusions {
  border: 0;
  margin: 0;
  padding: 0;
  display: inline-flex;
  justify-content: space-around;
  flex: auto;

  &.hidden {
    display: none;
    visibility: hidden;
  }
}

#alt-prompt {
  display: inline-block;
  border: 0;
  margin: 0;
  padding: 0;

  &.hidden {
    display: none;
    visibility: hidden;
  }
}

.promptextra {
  padding: 0.2em;
  display: inline-flex;
  line-height: 0.8em;
  padding: 0;
  outline: 0;
  margin: 0.2em 0 0.1em;
}
.promptextra label {
  background: royalblue;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  border-radius: 5px 0 0 5px;
  padding: 0.5em;
  margin-right: 0;
  border-right: 2px solid aliceblue;
  color: aliceblue;
  text-transform: uppercase;
}
.promptextra span {
  padding: 0.5em;
  background: aliceblue;
  color: darkslategrey;
  border-radius: 0 2px 2px 0;
  text-transform: uppercase;
  margin-left: 0;
}

#prompt-bar {
  background: rgb(4, 103, 35);
  width: 100%;
  height: 25px;
  line-height: 25px;
  border-radius: 10px;
  padding-left: 10px;
}

.hidden {
  display: none;
}

.prompt {
  background: rgb(4, 103, 35);
  width: auto;
  display: inline-block;
  padding: 1px;
  border-radius: 0.5em;
}

#buttonbit {
  display: inline-block;
  background: rgba(183, 205, 175, 0.31);
  position: absolute;
  margin: 15px 25px 15px 15px;
  width: auto;
  height: auto;
  border-radius: 1em;
  z-index: 10;
  top: 0;
  left: 0;
}

.macrobtn {
  display: block;
  visibility: visible;
  min-width: 60px;
  background: rgba(183, 205, 175, 0.56);
  color: white;
  text-align: center;
  border-radius: 10px;
  font-size: x-large;
  margin: 0.25em;

  &.hidden {
    display: none;
    visibility: hidden; //screen-readers sometimes don't honour just display: none
  }
}

/* Font */

.pale {
  background: rgba(255, 255, 255, 0.05);
}

.paler {
  background: rgba(255, 255, 255, 0.15);
}

/* Input */

.full.height {
  height: 100%;
  overflow: hidden;
}

#output-segment {
  height: 100%;
  padding: 40px 0px 81px 0px;
  margin-bottom: 0;
}

#input-segment {
  padding: 0px;
  border: none;
  margin-top: -79px;
}

#input-box-send {
  cursor: pointer;
}

#output-scroller {
  position: absolute;
  width: 100%;
  //  overflow:auto;
  height: auto !important; //for nano
  top: 40px;
  bottom: 75px;
}

#inputMessage {
  /*border: 10px solid #000;*/
  /*bottom: 0;*/
  height: 40px;
  /*left: 0;*/
  /*outline: none;*/
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  /*position: absolute;*/
  /*right: 0;*/
  font-size: 150%;
  width: 100%;
  background: inherit;
  background-color: rgb(48, 58, 65);
  color: white;
}

/*--------------
  Transparent
---------------*/

.ui.transparent.input {
  ::placeholder {
    color: #fff;
  }
}

.ui.transparent.menu {
  opacity: 0.95;
}

.fixed.launch.button {
  position: fixed;
  top: 63px;
  left: -1px;

  z-index: 10;

  width: 55px;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s width ease, 0.5s transform ease;
}

.fixed.launch.button .text {
  position: absolute;
  white-space: nowrap;
  top: auto;
  left: 54px;
  opacity: 0;
  transition: 0.3s opacity 0.3s;
}
.fixed.launch.button:hover {
  width: 130px;
}

.fixed.launch.button:hover .text {
  opacity: 1;
}

.launch.button .icon {
  margin-left: 0px;
}

.sidebar {
  display: none;
}
@media @desktop {
  .sidebar {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    padding-top: 38px;
    width: 230px;
    .map {
      font-size: 12px;
    }
  }
  #right-pane {
    margin-left: 230px;
  }
}

.infobar {
  .bar {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 0%;
    height: 1px;
    &.health {
      background: red;
    }
    &.mana {
      background: blue;
    }
  }
}

#mini-manual {
  color: rgb(101, 100, 100);

  ul {
    list-style: disc;
  }
}

#input-box-outer,
#input-box {
  padding: 0.2em;
  height: 1.5em;
  line-height: 1.3em;
}
#input-box {
  color: #101010;
  background: aliceblue;
  // box-shadow: 0px 2px 2px rgba(20, 20, 20, 0.7) inset;
}

#input-indicator {
  text-align: center;
  margin-top: -75px;
  color: white;
  z-index: 5;
  width: 100%;
  height: 1em;
}

#input-indicator > i {
  color: green;
}
