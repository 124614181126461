@indent: 10px;
@curve-radius: 0.2em;
@vert-spacer: 1em;

.horz-gradient(@color1,@color2) {
  background-image: -webkit-gradient(
    linear,
    left top,
    left top,
    color-stop(0%, @color1),
    color-stop(100% @color2)
  );
  background-image: -webkit-linear-gradient(left, @color1 0%, @color2 100%);
  background-image: -moz-linear-gradient(left, @color1 0%, @color2 100%);
  background-image: -ms-linear-gradient(left, @color1 0%, @color2 100%);
  background-image: -o-linear-gradient(left, @color1 0%, @color2 100%);
  background-image: linear-gradient(left, @color1 0%, @color2 100%);
}

.vert-gradient(@color1,@color2) {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, @color1),
    color-stop(100% @color2)
  );
  background-image: -webkit-linear-gradient(top, @color1 0%, @color2 100%);
  background-image: -moz-linear-gradient(top, @color1 0%, @color2 100%);
  background-image: -ms-linear-gradient(top, @color1 0%, @color2 100%);
  background-image: -o-linear-gradient(top, @color1 0%, @color2 100%);
  background-image: linear-gradient(top, @color1 0%, @color2 100%);
}

/* ensure all blocks are full-width and clear of other floating content */
.messages {
  //top, right, bottom, left
  padding: @vert-spacer @indent @vert-spacer @indent;

  .block,
  .line {
    width: 100%;
    // white-space: pre-wrap;
  }

  .block > .block {
    margin-left: @indent;
  }

  /*
  stdio divs indicate response from a specified command,
  indent slightly with a curved white border on the left.
*/
  .stdio {
    margin-top: (@vert-spacer / 4);
    margin-bottom: (@vert-spacer / 4);
    padding-top: (@vert-spacer / 2);
    padding-bottom: (@vert-spacer / 2);
    width: 100%;
    .cmd {
      color: rgb(37, 188, 36);
      float: right;
    }

    .cmd-inner {
      font-size: 0.9rem;
    }
  }

  .stdio + .stdio {
    margin-top: 0;
  }

  .ui.table {
    background: inherit !important;
    margin-top: 0px;

    th {
      color: white !important;
    }
  }
}

/* Basic text, otherwise untagged */

.unparsed {
  white-space: pre-wrap;
}

/* fixed-width font, space lines more closely to help visual layouts */

.monospaced {
  font-family: @monospaced-family;

  line-height: 1.2rem;
  font-size: 0.9rem;
  white-space: pre-wrap;
}
.ansi {
  white-space: inherit;
}
/* This is really here to ensure that ANSI stuff avoids massive padding and has rounded corners */

.unparsed span {
  border-radius: 5px;
  padding: 0;
  display: inline-block;
}

/* Anything inside a ###msg@ or ###begin@ */

.avmsg {
  padding: 0;
  margin-left: -3px;
  margin-top: 3px;
  margin-bottom: 3px;
  max-width: 95%;

  /* The ###title= text inside an ###msg */

  & > .cmd {
    display: none;
  }

  & > .title {
    color: yellow;
    padding: 2px;
    border-radius: 2px;
    font-weight: 700;
  }

  /* The ###text= text inside an ###msg */

  & > .text {
    padding: 2px;
  }

  &.environs {
    background: rgba(64, 255, 128, 0.25);
  }

  &.location {
    /*background: rgba(64, 255, 128, 0.25);*/
  }
}

.map {
  text-align: center;
}

/* map and moving map */
.avmap {
  color: #efefef;
  padding: 0;
  margin-top: 3px;
  margin-bottom: 3px;
  display: inline-block;

  & > .loc {
    padding: 3px;
    color: yellow;
  }

  & > .region {
    padding: 3px;
    color: yellowgreen;
  }

  /* map content: fixed-width lines with no spacing between */
  & > .lines {
    line-height: 1.2rem;
    white-space: pre-wrap;
    font-family: @monospaced-family;
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    text-align: left;
  }
}
/* Tells, calling, and bug runes */

.block > .comms {
  padding-left: 0px;
}
.comms {
  max-width: 95%;

  /* "content" here is everything after the icon */

  .commscontent {
    /* background: rgba(255, 255, 255, 0.2); */
    color: #efefef;
    border-radius: 1px;
    padding: 0;
    display: inline-block;
  }

  /* translucent teracotta colour for bug-rune messages */
  &.bug .commscontent {
    background: rgba(253, 126, 80, 0.25);
    border: solid 1px rgba(253, 126, 80, 0.5);
  }

  /* Heavier font for the channel name */
  .channel {
    display: inline-block;
    padding: 2px;
    font-weight: 700;
  }

  /* Translucent white background and bold black text for the person name */
  .who {
    display: inline-block;
    padding: 1px;
    font-weight: bold;
    // background-color: rgba(255, 255, 255, 0.5);
    color: rgba(92, 201, 245, 0.8);
    /*border: 1px solid rgb(127,127,127);*/
    // border-radius: 1px;
  }

  /* red icon for outbound messages */
  &.to {
    .icon,
    .msg {
      color: rgba(255, 96, 96, 0.9);
    }
  }

  /* green icon for inbound messages */
  &.from {
    .icon {
      color: rgba(92, 201, 245, 0.8);
    }
  }

  /* coral bug icon  */
  &.bug {
    .icon,
    .channel {
      color: coral;
    }
  }

  /* faint white background for the city, if supplied (only used in novice-calling) */
  .city {
    display: inline-block;
    padding: 2px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .msg {
    display: inline-block;
    padding: 2px;
  }
}

.login.announcement {
  .name {
    font-weight: 700;
    color: white;
  }
}

.ui.table td {
  padding: 3px 3px;
}

.ui.table th {
  padding: 3px 3px;
}

.block span {
  border-radius: 1px;
}
