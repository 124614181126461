
/*
based on the Terminal.app theme: http://en.wikipedia.org/wiki/ANSI_escape_code#Colors
*/

@black: rgb(0,0,0);
@red: rgb(194, 54, 33);
@darkred: rgb(87, 15, 4);
@green: rgb(37, 188, 36);
@yellow: rgb(173, 173, 39);
@blue: rgb(73, 46, 225);
@magenta: rgb(211, 56, 211);
@cyan: rgb(51, 187, 200);
@white: rgb(203, 204, 205);

@bright-black: rgb(129, 131, 131);
@bright-red: rgb(252,57,31);
@bright-green: rgb(49, 231, 34);
@bright-yellow: rgb(234, 236, 35);
@bright-blue: rgb(88, 51, 255);
@bright-magenta: rgb(249, 53, 248);
@bright-cyan: rgb(20, 240, 240);
@bright-white: rgb(255,255,255);

.black-fg { color: @black !important; }
.red-fg { color: @red !important; }
.green-fg { color: @green !important; }
.yellow-fg { color: @yellow !important; }
.blue-fg { color: @blue !important; }
.magenta-fg { color: @magenta !important; }
.cyan-fg { color: @cyan !important; }
.white-fg { color: @white !important; }

.bright-black-fg { color: @bright-black !important; }
.bright-red-fg { color: @bright-red !important; }
.bright-green-fg { color: @bright-green !important; }
.bright-yellow-fg { color: @bright-yellow !important; }
.bright-blue-fg { color: @bright-blue !important; }
.bright-magenta-fg { color: @bright-magenta !important; }
.bright-cyan-fg { color: @bright-cyan !important; }
.bright-white-fg { color: @bright-white !important; }

.black-bg { /* black bg = transparent */ }
.red-bg { background-color: @darkred !important; }
.green-bg { background-color: @green !important; }
.yellow-bg { background-color: @yellow !important; }
.blue-bg { background-color: @blue !important; }
.magenta-bg { background-color: @magenta !important; }
.cyan-bg { background-color: @cyan !important; }
.white-bg { background-color: @white !important; }

.bright-black-bg { background-color: @bright-black !important; }
.bright-red-bg { background-color: @bright-red !important; }
.bright-green-bg { background-color:  @bright-green !important; }
.bright-yellow-bg { background-color: @bright-yellow !important; }
.bright-blue-bg { background-color: @bright-blue !important; }
.bright-magenta-bg { background-color: @bright-magenta !important; }
.bright-cyan-bg { background-color: @bright-cyan !important; }
.bright-white-bg { background-color: @bright-white !important; }